import React from "react";

function Footer(props) {
  const handleSetAbout = () => {
    props.prop.setAboutflag(true);
  };
  return (
    <div className="footer-main">
      <div className="footer-lists">
        <a href="#">
          <li
            onClick={() => {
              props.prop.setAboutflag(false);
            }}
          >
            Kasven-Technology
          </li>
        </a>
        <a href="#">
          <li onClick={handleSetAbout}> About Us</li>
        </a>
        <a href="#">
          <li> Products</li>
        </a>
        <a href="#">
          <li> Services</li>
        </a>
        <a href="#">
          <li> Alliances</li>
        </a>
        <a href="#">
          <li onClick={handleSetAbout}> Contact</li>
        </a>
      </div>
      <div className="ft-cp">© 2024 Proudly powered by Kasven-Technology</div>
    </div>
  );
}

export default Footer;
