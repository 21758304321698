import React from "react";

export default function ExampleCarouselImage(prop) {
  return (
    <div className="img-carsoul">
      <img src={`./images/${prop.text}.jpeg`}></img>
      <div className="text-corousel">
        <div className="text-left-carousel">
          <h3>WELCOME TO KASVEN IT SOLUTION</h3>
          <h1>Where Technology Meets Vision</h1>
          <p>The Ultimate Source Of Software</p>
          <p>call us: Finland: +358 46 902 2956 , USA: +1 415 605 3255</p>
        </div>
        <div className="text-right-carousel">
          <div>
            500+ Cyber Security Project Listening to you and answering with
            software
          </div>
          <div>
            100 % Service Guarantee We provide our customers with high-quality
            service and satisfaction.
          </div>
        </div>
      </div>
    </div>
  );
}
