import React from "react";
import { Link } from "react-router-dom";

export default function About(props) {
  return (
    <div style={{ marginTop: "50px" }}>
      <Link
        to="/"
        onClick={() => {
          props.prop.setAboutflag(false);
        }}
      >
        Back to Home
      </Link>
      <div className="about">
        <div className="abt-img">
          <img src="./images/img5.jpeg" />
        </div>
        <div className="abt-content">
          <div>
            <h2>About Us</h2>
            <h3>The Perfect Solution For All The Protection Question</h3>
            <p>
              We are a team of imaginative individuals with a distinct approach
              to information and communication technology projects, and our
              primary objective is to satisfy our clients. Together, our clients
              and we develop comprehensive IT solutions that complement their IT
              strategies. In order to improve the quality of project
              deliverables, we apply a methodical approach, frameworks, best
              practices, and processes. With a variety of alternatives, our IT
              services model covers all essential business operations.
            </p>
          </div>
        </div>
        <div className="contact-us">
          <h2>Contact Information</h2>
          <p>
            We are a team of creative people with a unique strategy to
            Information and Communication Technology projects and with the main
            goal of bringing satisfaction to our clients.
          </p>
          <div className="contact-number">
            <li>
              <strong>Phone</strong>: Finland: +358 46 902 2956 , USA: +1 415
              605 3255
            </li>
            <li>
              <strong>Email:</strong> info@kasven.com
            </li>
          </div>
        </div>
      </div>
    </div>
  );
}
