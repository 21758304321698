import React from "react";

export default function Services() {
  return (
    <div className="services-main">
      <div className="services-head">
        <h1>What We Offers</h1>
        <p>In the value of the digital world we empowering the connections</p>
      </div>
      <div className="services-body" id="serv">
        <div>
          <div
            style={{ display: "flex", flexDirection: "row" }}
            className="servicesp"
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                class="bi bi-list"
                viewBox="0 0 16 16"
                style={{ color: "red", display: "block", marginRight: "5px" }}
              >
                <path
                  fill-rule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                />
              </svg>
              &nbsp;&nbsp;
            </span>
            {/* style={{ border: "1px solid red" }} */}
            <h3>Application Testing</h3>
          </div>
          <p style={{ paddingLeft: "10px" }}>
            Application testing is a process of evaluating the functionality,
            performance, and usability of software applications to ensure that
            they meet the requirements and expectations of the end-users.
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                class="bi bi-list"
                viewBox="0 0 16 16"
                style={{ color: "red", display: "block", marginRight: "5px" }}
              >
                <path
                  fill-rule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                />
              </svg>
              &nbsp;&nbsp;
            </span>
            {/* style={{ border: "1px solid red" }} */}
            <h3>Web Application Management</h3>
          </div>

          <p style={{ paddingLeft: "10px" }}>
            Web application management refers to the process of maintaining,
            monitoring, and optimizing a web application to ensure that it runs
            smoothly and efficiently.
          </p>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                class="bi bi-list"
                viewBox="0 0 16 16"
                style={{ color: "red", display: "block", marginRight: "5px" }}
              >
                <path
                  fill-rule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                />
              </svg>
              &nbsp;&nbsp;
            </span>
            {/* style={{ border: "1px solid red" }} */}
            <h3>E-Commerce Devolpment</h3>
          </div>
          <p>
            E-commerce development refers to the process of creating and
            maintaining an online platform for buying and selling products or
            services.
          </p>
        </div>
        <div className="service-imglogo">
          <img src="./images/logo.png" />
        </div>
        <div>
          <div>
            <div
              style={{ display: "flex", flexDirection: "row" }}
              className="servicesp"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="currentColor"
                  class="bi bi-list"
                  viewBox="0 0 16 16"
                  style={{ color: "red", display: "block", marginRight: "5px" }}
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                  />
                </svg>
                &nbsp;&nbsp;
              </span>
              {/* style={{ border: "1px solid red" }} */}
              <h3>Application Management</h3>
            </div>
            <p style={{ paddingLeft: "10px" }}>
              Application management refers to the process of managing software
              applications throughout their lifecycle, from development and
              testing to deployment, maintenance, and retirement.
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="currentColor"
                  class="bi bi-list"
                  viewBox="0 0 16 16"
                  style={{ color: "red", display: "block", marginRight: "5px" }}
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                  />
                </svg>
                &nbsp;&nbsp;
              </span>
              {/* style={{ border: "1px solid red" }} */}
              <h3>Managed It Services</h3>
            </div>

            <p style={{ paddingLeft: "10px" }}>
              Managed IT services refer to the outsourcing of a company's IT
              functions and responsibilities to a third-party service provider.
            </p>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="currentColor"
                  class="bi bi-list"
                  viewBox="0 0 16 16"
                  style={{ color: "red", display: "block", marginRight: "5px" }}
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                  />
                </svg>
                &nbsp;&nbsp;
              </span>
              {/* style={{ border: "1px solid red" }} */}
              <h3>Business Consulting</h3>
            </div>
            <p>
              Business consulting refers to the process of providing expert
              advice and guidance to businesses to help them improve their
              performance, efficiency, and profitability.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
