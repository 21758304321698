import React from "react";
import { Card } from "react-bootstrap";

export default function Cards(props) {
  const arr = new Map();
  arr.set(
    "IT industry",
    "For extreme flexibility, rapidity, and efficiency, a robust IT strategy is critical. Kasven IT Solutions help our customers make the most of their IT investments — from providing system integration solution, application development and management services and testing solutions."
  );
  arr.set(
    "IT Consultancy",
    "Kasven IT Solutions defines an organization as the zone of confluence of capabilities brought together by people, systems and processes. The processes are helping the organization to assemble the capabilities of people and systems. The goal of an organization is to efficiently and effectively use these capabilities to create products or services for their customers."
  );
  arr.set(
    "Qualified Staff",
    "Qualified staff refers to employees who possess the necessary skills, education, training, and experience to perform their job duties effectively and efficiently. Having qualified staff is crucial for any organization, as they are the backbone of the business and play a vital role in achieving the company's goals and objectives."
  );
  arr.set(
    "Reliable Support",
    "Reliable support refers to the ability of a company to provide consistent and dependable assistance to its customers whenever they need it. This can include technical support, customer service, or any other form of assistance that a customer might require."
  );
  return (
    <Card className="card-items" style={{ width: "18rem" }}>
      <Card.Body>
        <Card.Title>{props.text}</Card.Title>
        {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
        <Card.Text>{arr.get(props.text)}</Card.Text>
        {/* <Card.Link href="#">Card Link</Card.Link> */}
        {/* <Card.Link href="#">Another Link</Card.Link> */}
      </Card.Body>
    </Card>
  );
}
