import React, { useState } from "react";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import CarouselItem from "./CarouselItem";
import Cards from "./Cards";
import Footer from "./Footer";
import About from "./About";
import Services from "./Services";

export default function Home() {
  const [aboutFlag, setAboutflag] = useState(false);
  return (
    <>
      <div className="main">
        <NavBar prop={{ aboutFlag, setAboutflag }} />
        <div className="carousal">
          <CarouselItem />
        </div>
        {!aboutFlag ? (
          <>
            <div className="cards">
              <Cards text="IT industry" />
              <Cards text="IT Consultancy" />
              <Cards text="Qualified Staff" />
              <Cards text="Reliable Support" />
            </div>
            <Services />
          </>
        ) : (
          <About prop={{ aboutFlag, setAboutflag }} />
        )}
      </div>

      <Footer prop={{ aboutFlag, setAboutflag }} />
    </>
  );
}
