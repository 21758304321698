import React from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
export default function NavBar(props) {
  const handleSetAbout = () => {
    props.prop.setAboutflag(true);
  };
  return (
    <>
      <Navbar bg="dark" data-bs-theme="dark">
        <div className="logo-div" style={{ color: "wheat" }}>
          <img className="logo" src="./images/logo.png" />
        </div>
        <Container className="d-flex justify-content-end ">
          <Nav className="text-white">
            <Nav.Link href="#home" className="kasven">
              {" "}
              <Link
                style={{ textDecoration: "bold" }}
                to="/"
                onClick={() => {
                  props.prop.setAboutflag(false);
                }}
              >
                Kasven Technology
              </Link>
            </Nav.Link>
            <NavDropdown title="Services" id="navbarScrollingDropdown">
              <NavDropdown.Item
                href="#serv"
                onClick={() => {
                  props.prop.setAboutflag(false);
                }}
              >
                Software Development
              </NavDropdown.Item>
              <NavDropdown.Item href="#serv">Web Design</NavDropdown.Item>
              <NavDropdown.Item href="#serv">
                Technical Support
              </NavDropdown.Item>
              <NavDropdown.Item href="#serv">Consultancy</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Products" id="navbarScrollingDropdown">
              <NavDropdown.Item href="#serv">
                Sales Analist System
              </NavDropdown.Item>
              <NavDropdown.Item href="#serv">
                Call Reporting System
              </NavDropdown.Item>
              <NavDropdown.Item href="#serv">Agenda</NavDropdown.Item>
              <NavDropdown.Item href="#serv">
                Mobile Development
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link onClick={handleSetAbout}>About US</Nav.Link>

            <Nav.Link onClick={handleSetAbout}>Contact</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}
